@tailwind base;

@tailwind components;
 
.fat-link {
  text-decoration: none;
  color: inherit;
  position: relative;
  background-image: linear-gradient(to right, theme('colors.brand'), theme('colors.brand'));
  background-position: 0 1.04rem;
  background-repeat: repeat-x;
  background-size: 1rem 0.6rem;
  padding-bottom: 0.3rem;
  @apply px-1;
  @apply -mx-1;
  @apply ease-1;
}

.fat-link:hover {
  background-position: 0 0;
  background-size: 1rem 2rem;
  background-image: linear-gradient(to right, theme('colors.brand-trans'), theme('colors.brand-trans'));
  @apply pt-1;
}
 
@tailwind utilities;
 
.ease-1 {
  transition: all 0.1s ease-in-out;
}
.ease-2 {
  transition: all 0.2s ease-in-out;
}